import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  ApiLanguageService,
  AppConfig,
  AuthenticationService,
  DefaultErrorHandler,
  DefaultUserService,
  EclickCoreModule,
  HeaderInterceptor,
  initApiFactory,
  initAuthFactory,
  LanguageService,
  NewErrorInterceptor,
  TokenInterceptor
} from '@eclick/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { GyermelyiUserService } from './core/services/gyermelyi-user/gyermelyi-user.service';
import { SharedModule } from './shared/shared.module';
import { AppInitService } from './core/services/app-init/app-init.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';

const config: AppConfig = {
  apiUrl: 'https://admin.tilitoli.hu/api/',
  apiVersion: '1.0',
  supportedLanguages: ['hu', 'ro'],
  refreshTokenAvailable: true,
  dbPrefix: 'gyermelyi'
};

const cookieConfig: any = {
  cookie: {
    domain: 'tilitoli.hu'
  },
  palette: {
    popup: {
      background: '#1b3e93'
    },
  },
  content: {
    allow: 'Elfogadom',
    message: 'A Gyermelyi Tili-Toli weboldalain sütiket használunk annak érdekében, hogy látogatóink igényeinek megfelelő szolgáltatásokat nyújthassunk, illetve számukra releváns hirdetéseket jeleníthessünk meg vagy küldhessünk a későbbiekben.',
    dismiss: 'Elfogadom',
    deny: 'Elutasítom',
    link: 'Sütinyilatkozat',
    href: '#',
    policy: 'Cookie szabályzat'
  }
};

export function ErrorInterceptorFactory(errorHandler: DefaultErrorHandler) {
  return (new NewErrorInterceptor(config, errorHandler));
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
    EclickCoreModule.forRootWithoutInterceptors(config),
    SharedModule,
    NgxScrollTopModule,
    SocialLoginModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('341079087058720'),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: DefaultUserService, useExisting: GyermelyiUserService
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useFactory: ErrorInterceptorFactory, deps: [DefaultErrorHandler], multi: true
    },
    {
      provide: APP_INITIALIZER, useFactory: initAuthFactory, deps: [AuthenticationService], multi: true
    },
    {
      provide: APP_INITIALIZER, useFactory: initApiFactory, deps: [AppInitService, ApiLanguageService, LanguageService], multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
