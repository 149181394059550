import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@eclick/core';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/game-over/game-over.module').then(m => m.GameOverModule),
  },
  // {
  //   path: 'sikeres-regisztracio',
  //   loadChildren: () => import('./pages/successful-registration-page/successful-registration-page.module').then(m => m.SuccessfulRegistrationPageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'jatek',
  //   loadChildren: () => import('./pages/game-over/game-over.module').then(m => m.GameOverModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'profilom',
  //   loadChildren: () => import('./pages/profile-page/profile-page.module').then(m => m.ProfilePageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'nyeremenyek',
  //   loadChildren: () => import('./pages/prizes-page/prizes-page.module').then(m => m.PrizesPageModule),
  //   canActivate: []
  // },
  // {
  //   path: 'login',
  //   redirectTo: '',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'gyik',
  //   loadChildren: () => import('./pages/faq-page/faq-page.module').then(m => m.FaqPageModule)
  // },
  // {
  //   path: 'jatek-vege',
  //   loadChildren: () => import('./pages/game-over/game-over.module').then(m => m.GameOverModule)
  // },
  // {
  //   path: 'elfelejtett-jelszo',
  //   loadChildren: () => import('./pages/forgotten-password-page/forgotten-password-page.module').then(m => m.ForgottenPasswordPageModule)
  // },
  // {
  //   path: 'uj-jelszo/:token',
  //   loadChildren: () => import('./pages/new-password-page/new-password-page.module').then(m => m.NewPasswordPageModule)
  // },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
