import { Injectable } from '@angular/core';
import { LoginComponentStatus } from '../../definitions/enums/login-component.status.enum';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginComponentService {

  private currentStatus: LoginComponentStatus = LoginComponentStatus.LOGIN;

  onChange = new Subject<LoginComponentStatus>();

  change(status: LoginComponentStatus) {
    this.currentStatus = status;
    this.onChange.next(status);
  }

  getStatus() {
    return this.currentStatus;
  }
}
