import { Injectable } from '@angular/core';
import { Documents } from '../../definitions/entities/documents';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {


  documents: Documents;

  constructor() { }

  setDocuments(documents: Documents) {
    this.documents = documents;
  }

  getDocuments() {
    return this.documents;
  }

}
