import { Injectable } from '@angular/core';
import { UserService } from '@eclick/core';
import { GyermelyiUser } from '../../definitions/entities/gyermelyi-user';
import { Observable } from 'rxjs';
import { LevelsResponse } from '../../definitions/responses/get/levels.response';
import { Level } from '../../definitions/entities/level';
import { UserResponse } from '../../definitions/responses/user.response';

@Injectable({
  providedIn: 'root'
})
export class GyermelyiUserService extends UserService<GyermelyiUser> {

  getLevels() {
    return new Observable<Level[]>((subscriber) => {
      this.api.get<LevelsResponse>('levels').subscribe((response) => {
        subscriber.next(response.data.levels);
        subscriber.complete();
      }, () => {
        subscriber.next([]);
        subscriber.complete();
      });
    });
  }

  updateProfile(data: any) {
    return new Observable<any>((subscriber) => {
      this.api.post<UserResponse>('user/profile', data).subscribe((response) => {
        this.setUserData(response.data.user).subscribe(() => {
          subscriber.next();
          subscriber.complete();
        }, () => {
          subscriber.next();
          subscriber.complete();
        });
      }, (error) => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }
}
