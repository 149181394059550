import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService } from '@eclick/core';
import { NavigationEnd, Router } from '@angular/router';
import { HeaderConfig } from '../../../core/definitions/entities/header-config';
import { DefaultHeaderConfig, HeaderConfigs } from '../../../core/configs/header.config';
import { LoginComponentService } from '../../../core/services/login-component/login-component.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  hasBackground = false;
  isBackgroundForced = false;
  scrollTopOffset = 0;

  loggedIn = false;
  opened = false;

  hidden = true;
  visible = false;


  constructor(private auth: AuthenticationService, private router: Router, public lc: LoginComponentService) {
    this.loggedIn = this.auth.isLoggedIn();
    this.auth.onAuthChange().subscribe(() => {
      this.loggedIn = this.auth.isLoggedIn();
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        let config: HeaderConfig;

        for (const pattern in HeaderConfigs) {
          const re = new RegExp(pattern, 'g');
          if (re.test(ev.url)) {
            config = HeaderConfigs[pattern];
          }
        }

        if (!config) {
          config = DefaultHeaderConfig;
        }

        this.isBackgroundForced = config.hasBackground;

        this.onWindowScroll();
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollTop = window.scrollY;
    if (scrollTop > this.scrollTopOffset) {
      this.hasBackground = true;
    } else if (scrollTop <= this.scrollTopOffset && !this.isBackgroundForced) {
      this.hasBackground = false;
    }
  }

  logout() {
    this.auth.logout().subscribe(() => {
      window.location.reload();
    });
  }

  toggle() {
    this.opened = !this.opened;
  }

}
