import { Injectable } from '@angular/core';
import { InitResponse } from '../../definitions/responses/get/init.response';
import { ApiLanguageService, ApiResponse, ApiService, AuthenticationService, BaseAppInitService } from '@eclick/core';
import { DocumentsService } from '../documents/documents.service';
import { FaqService } from '../faq/faq.service';
import { GyermelyiUserService } from '../gyermelyi-user/gyermelyi-user.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService extends BaseAppInitService<InitResponse> {

  constructor(protected api: ApiService, protected auth: AuthenticationService, protected apiLanguage: ApiLanguageService,
              private userService: GyermelyiUserService, private documentsService: DocumentsService, private faqService: FaqService) {
    super(api, auth, apiLanguage);
  }

  processData(response: ApiResponse<InitResponse>): any {
    const user = response.data.user;

    if (user) {
      this.userService.setUserData(user).subscribe();
    }

    this.documentsService.setDocuments(response.data.documents);
    this.faqService.setFaqs(response.data.faqs);
  }
}
