<footer>
  <div class="container">
    <div class="row">
      <div class="footer-content">
        <div class="logos">
          <div class="gyermelyi">
            <a href="#"><img src="/assets/images/home/logo.svg" alt="Gyermelyi logo"></a>
          </div>
          <div class="social">
            <a href="https://www.facebook.com/gyermelyi" target="_blank">
              <div class="facebook-logo hvr-pulse-grow">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 252.1 245.6" style="enable-background:new 0 0 252.1 245.6;" xml:space="preserve">
                  <style type="text/css">
                    .st0 {
                      fill: #FFFFFF;
                    }
                  </style>
                  <g>
                    <path class="st0" d="M4.3,218.4c0-63.4,0-126.9,0-190.3c0.2-0.8,0.4-1.6,0.5-2.4c2-11.7,10.8-21,22.5-23.4c1.1-0.2,2.3-0.4,3.4-0.6
                      c63.4,0,126.9,0,190.3,0c0.3,0.1,0.7,0.2,1,0.2c1.4,0.3,2.9,0.5,4.3,0.8c11.1,3.1,19,12,20.7,23.6c0,0.1,0.1,0.3,0.2,0.4
                      c0,64.3,0,128.6,0,192.8c-0.1,0.7-0.2,1.3-0.3,2c-2.2,11.1-10.7,19.9-21.7,22.4c-1.1,0.2-2.1,0.4-3.2,0.6c-64.1,0-128.1,0-192.2,0
                      c-0.7-0.1-1.4-0.3-2.1-0.4C15.7,242,6.2,232,4.7,220C4.6,219.4,4.4,218.9,4.3,218.4z M135.4,118.9c0-0.6,0-1,0-1.5
                      c0-6.2,0-12.4,0-18.6c0-2.4,0.1-4.9,0.5-7.2c2.3-13.4,9.3-23.6,20.9-30.7c6.2-3.8,12.9-5.8,20.2-6c7-0.2,14-0.1,20.9-0.1
                      c0.2,0,0.3,0.1,0.5,0.1c0.2,1.7,0.1,28.4-0.1,29.3c-0.4,0-0.8,0-1.2,0c-6,0-12,0-18,0c-1.7,0-3.3,0.3-4.9,0.8
                      c-5.7,2-9.4,7.2-9.4,13.6c0,6.4,0,12.7,0,19.1c0,0.3,0,0.7,0.1,1.1c11.4,0,22.7,0,34.1,0c-1.6,9.9-3.2,19.6-4.9,29.5
                      c-9.8,0-19.5,0-29.3,0c0,25.8,0,51.5,0,77.3c0.5,0,1,0,1.5,0c17.2,0,34.5,0,51.7,0c0.3,0,0.6,0,0.9,0c4-0.1,7.4-2.5,8.8-6.2
                      c0.5-1.3,0.6-2.6,0.6-4c0-61.5,0-123,0-184.5c0-0.3,0-0.5,0-0.8c0-4.1-2.7-7.6-6.5-8.9c-1.2-0.4-2.4-0.5-3.7-0.5
                      c-61.4,0-122.9,0-184.3,0c-0.3,0-0.7,0-1,0c-2.8,0.1-5.1,1.2-7,3.3c-1.7,2-2.3,4.4-2.3,7c0,61.4,0,122.9,0,184.3c0,0.3,0,0.6,0,0.9
                      c0.1,3,1.3,5.4,3.6,7.3c1.9,1.6,4.1,2.1,6.6,2.1c33.4,0,66.9,0,100.3,0c0.2,0,0.5,0,0.7,0c0.3,0,0.5-0.1,0.7-0.1
                      c0.3-2.6,0.1-76.3-0.2-77.2c-9.3,0-18.7,0-28.1,0c0-9.9,0-19.6,0-29.4C116.5,118.9,125.8,118.9,135.4,118.9z"/>
                  </g>
              </svg>
              </div>
            </a>
            <a href="https://www.instagram.com/tesztagram/" target="_blank">
              <div class="instagram-logo hvr-pulse-grow">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 252.1 245.6" style="enable-background:new 0 0 252.1 245.6;" xml:space="preserve">
                  <g>
                    <path class="st0" d="M248.7,72.8c0,32.7,0,65.4,0,98.1c-0.1,0.6-0.2,1.2-0.2,1.8c0,3.3-0.3,6.5-0.7,9.8c-0.7,6-2,11.9-3.9,17.6
                      c-3.6,10.6-9.6,19.6-18,27c-7.9,7-17.2,11.5-27.4,14.1c-7.1,1.8-14.4,2.6-21.7,2.9c-5,0.2-9.9,0.3-14.9,0.5c-1.3,0-2.7,0.2-4,0.2
                      c-20.5,0-40.9,0-61.4,0c-0.2,0-0.4-0.1-0.7-0.1c-5.1-0.2-10.1-0.4-15.2-0.5c-4.4-0.1-8.8-0.4-13.2-0.9c-5.2-0.6-10.4-1.7-15.4-3.3
                      c-13.1-4-23.7-11.5-32-22.3c-7-9.2-10.7-19.7-12.6-30.9c-0.9-5-1.3-10.1-1.4-15.3c-0.3-9.3-0.5-18.5-0.8-27.8
                      c0-0.4-0.1-0.8-0.1-1.2c0-13.1,0-26.1,0-39.2c0-0.3,0.1-0.7,0.1-1c0.1-2.7,0.1-5.5,0.2-8.2c0.1-4.3,0.1-8.7,0.3-13
                      c0.2-5.4,0.5-10.9,1-16.3c0.5-5.9,1.7-11.6,3.5-17.2c3.4-10.8,9.1-20.1,17.5-27.9c6.8-6.3,14.7-10.8,23.5-13.7
                      c8-2.6,16.1-3.8,24.5-4.3c5.7-0.3,11.4-0.4,17-0.6c1.3,0,2.6-0.1,3.9-0.2c20.4,0,40.8,0,61.2,0c0.3,0,0.5,0.1,0.8,0.1
                      c4,0.1,8,0.2,12,0.3c4.3,0.2,8.5,0.4,12.8,0.7c5,0.3,9.8,1.1,14.7,2.3c11.9,3,22.4,8.4,31.1,17.2c5.8,5.8,10.3,12.5,13.3,20.1
                      c3.8,9.4,5.7,19.1,6,29.2C248.6,71.5,248.7,72.1,248.7,72.8z M227.4,123c0,0,0.1,0,0.1,0c-0.1-10.1-0.1-20.2-0.2-30.2
                      c-0.1-8.1-0.3-16.2-0.9-24.3c-0.5-6.1-1.7-12.1-4-17.8c-4.1-10.2-11.2-17.6-21.2-22c-6.4-2.9-13.3-4.2-20.3-4.6
                      c-4.8-0.3-9.7-0.5-14.5-0.7c-5.8-0.2-11.5-0.3-17.3-0.2c-21.2,0-42.5-0.4-63.7,0.4c-5,0.2-10.1,0.3-15.1,0.9
                      c-4.3,0.5-8.6,1.4-12.7,2.8c-8.6,2.9-15.5,8.1-20.7,15.5C33,48.1,31,54.4,29.7,60.9c-0.9,4.5-1.3,9.1-1.4,13.8
                      c-0.2,7.1-0.6,14.3-0.6,21.4c-0.1,14.4-0.1,28.9,0,43.3c0,6.5,0.1,13.1,0.2,19.6c0.1,6.5,0.3,13.1,0.9,19.6
                      c0.6,5.9,1.8,11.7,4.1,17.2c4.3,10.3,11.6,17.6,21.9,21.9c6.6,2.8,13.5,3.9,20.6,4.4c5.3,0.3,10.6,0.5,15.9,0.7
                      c6.8,0.2,13.7,0.2,20.5,0.2c13.2,0,26.4-0.1,39.6-0.1c7.1,0,14.3-0.1,21.4-0.5c3.7-0.2,7.4-0.4,11.1-0.7c4.3-0.4,8.4-1.2,12.5-2.5
                      c10.6-3.4,18.7-9.9,24.1-19.7c3.3-6.1,4.9-12.6,5.6-19.4c0.4-3.6,0.6-7.2,0.7-10.7c0.3-6.8,0.5-13.5,0.6-20.3
                      C227.4,140.2,227.4,131.6,227.4,123z"/>
                                      <path class="st0" d="M127.5,60.2c34.1-0.2,62.7,27.8,62.6,62.7c0,34.4-28,62.7-62.9,62.6c-35-0.1-62.9-28.8-62.5-63.4
                      C65.1,87.8,93.3,60,127.5,60.2z M168,122.9c0-22.2-17.8-40.6-40.6-40.6c-22.6,0-40.6,18.2-40.6,40.7c0,22.4,18.2,40.6,40.6,40.6
                      C149.8,163.5,168,145.3,168,122.9z"/>
                                      <path class="st0" d="M177.9,57.6c0-8.1,6.6-14.6,14.7-14.6c8.1,0,14.7,6.6,14.6,14.7c-0.1,8.7-7.2,14.7-14.8,14.6
                      C184.4,72.3,177.8,65.6,177.9,57.6z"/>
                  </g>
                </svg>
              </div>
            </a>
          </div>
        </div>
<!--        <div class="infos">-->
<!--          <p>{{'footer.has-question' | translate}}</p>-->
<!--          <p>Olvasd el a <a [routerLink]="'/gyik'">Gyakran ismételt kérdéseket</a></p>-->
<!--          <div class="messenger">-->
<!--            <div class="messenger-cta">-->
<!--              <p>Vagy írj üzenetet a Gyermelyinek <a href="#">Facebook Messengeren.</a></p>-->
<!--            </div>-->
<!--            <a href="https://m.me/gyermelyi" target="_blank">-->
<!--              <div class="messenger-button hvr-pulse-grow">-->
<!--                &lt;!&ndash;              <img src="/assets/images/home/messenger-icon.png" alt="Messenger logo">&ndash;&gt;-->
<!--                <div class="messenger-logo">-->
<!--                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
<!--                       viewBox="0 0 252.1 245.6" style="enable-background:new 0 0 252.1 245.6;" xml:space="preserve">-->
<!--                    <g>-->
<!--                      <g>-->
<!--                        <path class="st0" d="M51.9,243.8c-0.9,0-1.8,0-2.7,0c-0.1,0-0.1-0.1-0.2-0.1c-2.5-0.9-3.6-2.8-3.6-5.4c0-0.3,0-0.6,0-0.9-->
<!--                          c0-10.7,0-21.3,0-32c0-0.8-0.2-1.4-0.9-1.9C30.3,191,19.7,176,12.8,158.5c-4.3-11-6.8-22.4-7.5-34.2c-0.1-0.9-0.1-1.9-0.4-2.8-->
<!--                          c0-2,0-4.1,0-6.1c0.1-0.4,0.2-0.7,0.2-1.1c0.3-2.9,0.5-5.9,0.8-8.8c1.1-8.9,3.2-17.5,6.3-25.9C21.2,55.8,36,36.8,56.8,22.4-->
<!--                          c13.1-9,27.5-15.1,43.1-18.3c5.3-1.1,10.7-1.8,16.2-2.2c0.3,0,0.7,0.1,0.9-0.3c6.1,0,12.3,0,18.4,0c0.3,0.4,0.7,0.3,1,0.3-->
<!--                          c5.5,0.4,11.1,1.1,16.5,2.3c23.7,5.1,44,16.3,60.9,33.7C228.2,52.6,238,70.1,243.3,90c1.9,7.2,3.3,14.6,3.6,22.1-->
<!--                          c0,0.3,0.1,0.7,0.2,1c0,3.6,0,7.3,0,10.9c-0.3,0.1-0.2,0.4-0.2,0.6c-0.1,2.1-0.4,4.2-0.6,6.3c-1.3,10-3.8,19.7-7.7,29-->
<!--                          c-11.7,27.9-31.1,48.6-58.2,62.1c-15.5,7.7-32,11.5-49.3,11.9c-3.6,0.1-7.3,0-10.9-0.1c-4.3-0.2-8.6-0.6-12.9-1.3-->
<!--                          c-8.6-1.3-17-3.5-25.1-6.5c-0.6-0.2-1.1-0.2-1.7,0.2c-2.5,1.6-5,3.1-7.5,4.7C66,235.1,59,239.6,51.9,243.8z M45.5,147.9-->
<!--                          c0,2.5,1.6,4.5,3.9,5c1.4,0.3,2.7,0,4-0.7c14.3-7.8,28.5-15.6,42.8-23.4c2.7-1.5,5.4-3,8.2-4.4c1-0.5,0.8-0.6,1.8,0.2-->
<!--                          c0.1,0,0.1,0.1,0.2,0.1c8.9,7.7,17.9,15.3,26.8,23c1.6,1.4,3.2,2.7,4.7,4.1c2.3,1.9,5,1.8,7.2-0.2c0.2-0.2,0.4-0.4,0.6-0.6-->
<!--                          c19.8-19.8,39.5-39.5,59.3-59.3c0.3-0.3,0.6-0.6,0.9-0.9c2.3-2.9,1-7.1-2.6-8.1c-1.6-0.4-3,0-4.3,0.7-->
<!--                          c-16.9,9.2-33.9,18.5-50.8,27.7c-1.2,0.6-0.9,0.7-2-0.2c-8.6-7.4-17.2-14.7-25.8-22.1c-2-1.7-3.9-3.4-5.9-5-->
<!--                          c-2.2-1.8-4.9-1.7-7,0.2c-0.2,0.2-0.5,0.5-0.7,0.7C87,104.3,67.3,124,47.6,143.7c-0.3,0.3-0.5,0.5-0.8,0.8-->
<!--                          C45.9,145.5,45.4,146.7,45.5,147.9z"/>-->
<!--                        <path class="st1" d="M51.9,243.8c7.1-4.2,14.1-8.7,21.1-13.1c2.5-1.6,5-3.1,7.5-4.7c0.6-0.4,1.1-0.4,1.7-0.2-->
<!--                          c8.1,3.1,16.5,5.2,25.1,6.5c4.3,0.6,8.6,1.1,12.9,1.3c3.6,0.2,7.3,0.2,10.9,0.1c17.3-0.4,33.8-4.2,49.3-11.9-->
<!--                          c27.1-13.4,46.5-34.1,58.2-62.1c3.9-9.3,6.4-19,7.7-29c0.3-2.1,0.6-4.2,0.6-6.3c0-0.2,0-0.5,0.2-0.6c0,39.9,0,79.9,0,119.8-->
<!--                          C182,243.8,117,243.8,51.9,243.8z"/>-->
<!--                        <path class="st1" d="M117,1.6c-0.2,0.4-0.6,0.2-0.9,0.3C110.7,2.3,105.3,3,100,4.1c-15.6,3.2-30,9.3-43.1,18.3-->
<!--                          C36,36.8,21.2,55.8,12.3,79.5c-3.1,8.4-5.2,17-6.3,25.9c-0.4,2.9-0.5,5.9-0.8,8.8c0,0.4-0.1,0.7-0.2,1.1c0-37.9,0-75.8,0-113.7-->
<!--                          C42.3,1.6,79.7,1.6,117,1.6z"/>-->
<!--                        <path class="st1" d="M247.1,113.1c-0.1-0.3-0.2-0.6-0.2-1c-0.3-7.5-1.6-14.9-3.6-22.1c-5.3-19.9-15.1-37.3-29.5-52.1-->
<!--                          C197,20.5,176.6,9.3,152.9,4.2C147.5,3,142,2.3,136.4,1.9c-0.3,0-0.8,0.1-1-0.3c37.2,0,74.4,0,111.7,0-->
<!--                          C247.1,38.8,247.1,75.9,247.1,113.1z"/>-->
<!--                        <path class="st1" d="M4.9,121.5c0.3,0.9,0.3,1.9,0.4,2.8c0.7,11.8,3.2,23.2,7.5,34.2c6.9,17.6,17.5,32.6,31.8,44.9-->
<!--                          c0.6,0.6,0.9,1.1,0.9,1.9c0,10.7,0,21.3,0,32c0,0.3,0,0.6,0,0.9c-0.1,2.6,1,4.5,3.6,5.4c0.1,0,0.1,0.1,0.2,0.1-->
<!--                          c-14.8,0-29.5,0-44.3,0C4.9,203,4.9,162.3,4.9,121.5z"/>-->
<!--                        <path class="st1" d="M45.5,147.9c0-1.3,0.4-2.4,1.4-3.4c0.3-0.3,0.5-0.5,0.8-0.8C67.3,124,87,104.3,106.7,84.7-->
<!--                          c0.2-0.2,0.5-0.5,0.7-0.7c2.1-1.9,4.8-2.1,7-0.2c2,1.7,3.9,3.3,5.9,5c8.6,7.4,17.2,14.7,25.8,22.1c1.1,0.9,0.9,0.8,2,0.2-->
<!--                          c16.9-9.2,33.9-18.5,50.8-27.7c1.4-0.8,2.8-1.2,4.3-0.7c3.6,1,4.9,5.2,2.6,8.1c-0.3,0.3-0.6,0.6-0.9,0.9-->
<!--                          c-19.8,19.8-39.5,39.5-59.3,59.3c-0.2,0.2-0.4,0.4-0.6,0.6c-2.2,2.1-4.9,2.2-7.2,0.2c-1.6-1.3-3.2-2.7-4.7-4.1-->
<!--                          c-8.9-7.7-17.9-15.3-26.8-23c-0.1,0-0.1-0.1-0.2-0.1c-0.9-0.8-0.8-0.7-1.8-0.2c-2.7,1.5-5.4,3-8.2,4.4-->
<!--                          c-14.3,7.8-28.6,15.6-42.8,23.4c-1.3,0.7-2.6,1.1-4,0.7C47.1,152.4,45.5,150.4,45.5,147.9z"/>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                </div>-->
<!--                <p>ÜZENET</p>-->
<!--              </div>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
        <div class="game-logo">
          <img src="/assets/images/home/Tili-toli-logo-stroke-nelkul.svg" alt="Tili Toli logo" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</footer>
