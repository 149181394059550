<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

<app-base-popup></app-base-popup>
<button
  ngxScrollTop
  [ngxScrollTopMode]="'classic'"
  class="gyermelyi-button round gradient-hover scroll-top hvr-grow">
  <span class="up"></span>
</button>
