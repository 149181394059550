import { Component, OnInit } from '@angular/core';

declare var Swiper: any;

@Component({
  selector: 'app-prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.scss']
})
export class PrizesComponent implements OnInit {

  swiper;

  // mySwiper = new Swiper('.swiper-container', {
  //   // Optional parameters
  //   direction: 'vertical',
  //   loop: true,
  //
  //   // If we need pagination
  //   pagination: {
  //     el: '.swiper-pagination',
  //   },
  //
  //   // Navigation arrows
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  //
  //   // And if we need scrollbar
  //   scrollbar: {
  //     el: '.swiper-scrollbar',
  //   },
  // });

  constructor() {
  }

  ngOnInit(): void {
    this.swiper = new Swiper('.swiper-container', {
      loop: false,
      //
      // // If we need pagination
      // pagination: {
      //   el: '.swiper-pagination',
      // },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

}
