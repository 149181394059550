<div class="page-section">
  <div class="container">

    <div class="title">
      <h1>{{'prizes.title' | translate}}</h1>
    </div>

    <div class="row prize-desktop">
      <div class="col-md-4">
        <div class="prize-wrapper">
          <div class="prize-title">
            <img src="/assets/images/home/prize-bubble.png" alt="Fődíj" class="img-fluid">
            <div class="text-box">
              <div class="type">Fődíj:</div>
              <div class="name">TEFAL MASTERCHEF GOURMET KONYHAGÉP</div>
            </div>
          </div>
          <div class="prize">
            <a [routerLink]="['/nyeremenyek']">
              <img src="/assets/images/home/fodij.png" alt="Fődíj" class="img-fluid hvr-bounce-in">
            </a>
          </div>
          <div class="text">
            <span>{{'prizes.info.main' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="prize-wrapper">
          <div class="prize-title">
            <img src="/assets/images/home/prize-bubble.png" alt="Fődíj" class="img-fluid">
            <div class="text-box">
              <div class="type">Közepes nyeremények:</div>
              <div class="name">TEFAL INGENIO EXPRETISE 3 DB-OS SERPENYŐ SZETT</div>
            </div>
          </div>
          <div class="prize">
            <a [routerLink]="['/nyeremenyek']">
              <img src="/assets/images/home/kozepes-nyeremeny.png" alt="Közepes nyeremény"
                   class="img-fluid hvr-bounce-in">
            </a>
          </div>
          <div class="text">
            <span>{{'prizes.info.secondary' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="prize-wrapper">
          <div class="prize-title">
            <img src="/assets/images/home/prize-bubble.png" alt="Fődíj" class="img-fluid">
            <div class="text-box">
              <div class="type">Kis nyeremények:</div>
              <div class="name">GYERMELYI AJÁNDÉKCSOMAG</div>
            </div>
          </div>
          <div class="prize">
            <a [routerLink]="['/nyeremenyek']">
              <img src="/assets/images/home/kis-nyeremenyek.png" alt="Kis nyeremények" class="img-fluid hvr-bounce-in">
            </a>
          </div>
          <div class="text">
            <span>{{'prizes.info.third' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Slider main container -->
  <div class="swiper-container prize-mobile">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide">
        <div class="prize-wrapper">
          <div class="prize-title">
            <img src="/assets/images/home/prize-bubble.png" alt="Fődíj" class="img-fluid">
            <div class="text-box">
              <div class="type">Fődíj:</div>
              <div class="name">TEFAL MASTERCHEF GOURMET KONYHAGÉP</div>
            </div>
          </div>
          <div class="prize">
            <a [routerLink]="['/nyeremenyek']">
              <img src="/assets/images/home/fodij.png" alt="Fődíj" class="img-fluid hvr-bounce-in">
            </a>
          </div>
          <div class="text">
            <span>{{'prizes.info.main' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="prize-wrapper">
          <div class="prize-title">
            <img src="/assets/images/home/prize-bubble.png" alt="Fődíj" class="img-fluid">
            <div class="text-box">
              <div class="type">Közepes nyeremények:</div>
              <div class="name">TEFAL INGENIO EXPRETISE 3 DB-OS SERPENYŐ SZETT</div>
            </div>
          </div>
          <div class="prize">
            <a [routerLink]="['/nyeremenyek']">
              <img src="/assets/images/home/kozepes-nyeremeny.png" alt="Közepes nyeremény"
                   class="img-fluid hvr-bounce-in">
            </a>
          </div>
          <div class="text">
            <span>{{'prizes.info.secondary' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="prize-wrapper">
          <div class="prize-title">
            <img src="/assets/images/home/prize-bubble.png" alt="Fődíj" class="img-fluid">
            <div class="text-box">
              <div class="type">Kis nyeremények:</div>
              <div class="name">GYERMELYI AJÁNDÉKCSOMAG</div>
            </div>
          </div>
          <div class="prize">
            <a [routerLink]="['/nyeremenyek']">
              <img src="/assets/images/home/kis-nyeremenyek.png" alt="Kis nyeremények" class="img-fluid hvr-bounce-in">
            </a>
          </div>
          <div class="text">
            <span>{{'prizes.info.third' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-prev"><img class="swiper-navigation-left"
                                         src="/assets/images/home/swiper-navigation-left.png" alt=""></div>
    <div class="swiper-button-next"><img class="swiper-navigation-right"
                                         src="/assets/images/home/swiper-navigation-left.png" alt=""></div>
  </div>
  <div class="container">
    <div class="info">
      <img src="/assets/images/home/info-ikon.svg" alt="Info">{{'prizes.click-image' | translate}}
    </div>
  </div>
</div>

