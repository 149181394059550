import { Directive, ElementRef } from '@angular/core';

/**
 * Password show directive
 */
@Directive({
  selector: '[appAppPassword]'
})
export class AppPasswordDirective {

  /**
   * Shown object
   */
  private shown = false;

  /**
   * Constructor
   * @param {ElementRef} el
   */
  constructor(private el: ElementRef) {
    this.setup();
  }

  /**
   * Toggle visibility
   * @param {HTMLElement} lmnt
   */
  toggle(lmnt: HTMLElement) {
    this.shown = !this.shown;
    if (this.shown) {
      this.el.nativeElement.setAttribute('type', 'text');
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
    }
  }

  /**
   * Setup directive
   */
  setup() {
    const parent = this.el.nativeElement.parentNode;
    const lmnt = document.createElement('div');
    lmnt.classList.add('show-it');
    lmnt.addEventListener('click', (event) => {
      this.toggle(lmnt);
    });
    parent.appendChild(lmnt);
  }

}
