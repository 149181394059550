<div class="top-container">

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content-wrapper">
          <div class="illustration">
            <img src="/assets/images/home/Tili-toli-logo-szines.svg" alt="Tili-Toli Logo">
          </div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
