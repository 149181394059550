import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { HeroComponent } from './components/hero/hero.component';
import { PrizesComponent } from './components/prizes/prizes.component';
import { BasePopupComponent } from './components/base-popup/base-popup.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AppPasswordDirective } from './directives/app-password.directive';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HeroComponent,
    PrizesComponent,
    BasePopupComponent,
    AppPasswordDirective
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    HeroComponent,
    PrizesComponent,
    BasePopupComponent,
    AppPasswordDirective
  ],
    imports: [
        CommonModule,
        RouterModule,
        NgxSmartModalModule.forRoot(),
        TranslateModule,
        ScrollToModule.forRoot(),
    ]
})
export class SharedModule {
}
