<header [class.has-background]="hasBackground || isBackgroundForced">
  <div class="container">
    <!--    <div class="row">-->
    <div class="wrapper">
      <nav>
        <div class="logo">
          <a [routerLink]="['']">
            <img src="/assets/images/home/logo.svg" alt="Gyermelyi Logo">
          </a>
        </div>
        <ul>
<!--          <li>-->
<!--            <a [routerLink]="['/']" [ngxScrollTo]="'#belepes'" routerLinkActive="active"-->
<!--               [routerLinkActiveOptions]="{ exact: true }">{{'header.home' | translate}}</a>-->
<!--          </li>-->
<!--          <li *ngIf="!loggedIn">-->
<!--            <a [routerLink]="['']" [ngxScrollTo]="'#belepes'">{{'header.game' | translate}}</a>-->
<!--          </li>-->
<!--          <li *ngIf="loggedIn">-->
<!--            <a [routerLink]="['/jatek']" routerLinkActive="active">{{'header.game' | translate}}</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a [routerLink]="['/nyeremenyek']" routerLinkActive="active">{{'header.prizes' | translate}}</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a [routerLink]="['/gyik']" routerLinkActive="active">{{'header.faq' | translate}}</a>-->
<!--          </li>-->
        </ul>
      </nav>
<!--      <div class="buttons-container" *ngIf="!loggedIn">-->
      <!--        <button class="gyermelyi-button" [routerLink]="''" [ngxScrollTo]="'#belepes'"-->
      <!--                (click)="lc.change(0)">{{'header.login' | translate}}</button>-->
      <!--        <button class="gyermelyi-button" [routerLink]="''" [ngxScrollTo]="'#belepes'"-->
      <!--                (click)="lc.change(1)">{{'header.registration' | translate}}</button>-->
      <!--      </div>-->
      <!--      <div class="buttons-container" *ngIf="loggedIn">-->
      <!--        <a [routerLink]="['/profilom']" routerLinkActive="active">-->
      <!--          <button class="gyermelyi-button">{{'header.profile' | translate}}</button>-->
      <!--        </a>-->
      <!--        <div class="logout-btn hvr-grow" (click)="logout()" title="Kilépés"></div>-->
      <!--        &lt;!&ndash;        <button class="gyermelyi-button" (click)="logout()">Kijelentkezés</button>&ndash;&gt;-->
      <!--      </div>-->
<!--      <button class="hamburger hamburger&#45;&#45;collapse is-active" type="button" [class.is-active]="opened"-->
<!--              (click)="toggle()">-->
<!--        <span class="hamburger-box">-->
<!--        <span class="hamburger-inner"></span>-->
<!--        </span>-->
<!--      </button>-->
    </div>
    <!--    </div>-->
  </div>
<!--  <div class="mobile-backdrop" [class.visible]="opened" (click)="opened = false"></div>-->
<!--  <div class="mobile" [class.visible]="opened">-->
<!--    <nav>-->
<!--      <ul>-->
<!--        <li>-->
<!--          <a [routerLink]="['/']" (click)="opened = false" routerLinkActive="active"-->
<!--             [routerLinkActiveOptions]="{ exact: true }">{{'header.home' | translate}}</a>-->
<!--        </li>-->
        <!--        <li *ngIf="loggedIn">-->
        <!--          <a [routerLink]="['/jatek']" (click)="opened = false"  routerLinkActive="active">{{'header.game' | translate}}</a>-->
        <!--        </li>-->
        <!--        <li *ngIf="!loggedIn">-->
        <!--          <a [routerLink]="['']" [ngxScrollTo]="'#belepes'" (click)="opened = false">{{'header.game' | translate}}</a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a [routerLink]="['/nyeremenyek']" (click)="opened = false"  routerLinkActive="active">{{'header.prizes' | translate}}</a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a [routerLink]="['/gyik']" (click)="opened = false"  routerLinkActive="active">{{'header.faq' | translate}}</a>-->
        <!--        </li>-->
<!--      </ul>-->
<!--    </nav>-->
    <!--    <div class="buttons-container" *ngIf="!loggedIn">-->
    <!--      <button class="gyermelyi-button" [routerLink]="''" [ngxScrollTo]="'#belepes'"-->
    <!--              (click)="lc.change(0); opened = false">{{'header.login' | translate}}</button>-->
    <!--      <button class="gyermelyi-button" [routerLink]="''" [ngxScrollTo]="'#belepes'"-->
    <!--              (click)="lc.change(1); opened = false">{{'header.registration' | translate}}</button>-->
    <!--    </div>-->

    <!--    <div class="buttons-container" *ngIf="loggedIn">-->
    <!--      <a [routerLink]="['/profilom']" (click)="opened = false">-->
    <!--        <button class="gyermelyi-button">{{'header.profile' | translate}}</button>-->
    <!--      </a>-->
    <!--      &lt;!&ndash;      <button class="gyermelyi-button" (click)="logout(); opened=false">Kijelentkezés</button>&ndash;&gt;-->
    <!--      <div class="logout-btn hvr-grow" (click)="logout(); opened = false"></div>-->
    <!--    </div>-->
<!--  </div>-->
</header>
