<ngx-smart-modal #basePopup identifier="basePopup" [customClass]="'nsm-centered'" [closable]="false">
  <button class="gyermelyi-button round small red hvr-grow close-btn" (click)="basePopup.close()">
    <img src="/assets/images/game/close.png">
  </button>
  <div class="content">
    <div *ngIf="basePopup.hasData()">
      <h1 [innerHTML]="basePopup.getData().title"></h1>
      <p [innerHTML]="basePopup.getData().text"></p>
    </div>
  </div>
</ngx-smart-modal>
