import { HeaderConfig } from '../definitions/entities/header-config';

export const HeaderConfigs: { [key: string]: HeaderConfig } = {
  '\/gyik': {
    hasBackground: true
  },
  '\/nyeremenyek': {
    hasBackground: true
  },
  '\/*-jelszo': {
    hasBackground: true
  }
};

export const DefaultHeaderConfig: HeaderConfig = {
  hasBackground: false
};
